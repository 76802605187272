import React, { useEffect, useState } from "react";
import { Button, Col, Layout, Menu, message, Row, Tag } from "antd";
import {
  ApartmentOutlined,
  BankOutlined,
  CalendarOutlined,
  ClusterOutlined,
  DashboardOutlined,
  GroupOutlined,
  HeartOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useLocation, Link, useNavigate, Outlet } from "react-router-dom";
import Logo from "../assets/images/logo.png";

const AdminLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg, sm } = useBreakpoint();
  const [isSider, setIsSider] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { SubMenu } = Menu;

  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    <Layout>
      <Header
        style={{
          height: "75px",
          lineHeight: "75px",
          position: lg ? "fixed" : "unset",
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
          borderBottom: "1px solid #ccc",
          boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <Row justify={!sm ? "start" : "space-between"} align="middle">
          <Col>
            <Button
              icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size="large"
              onClick={() => setIsSider(!isSider)}
            />
          </Col>
          <Col>
            <img
              onClick={() => navigate("/home/homepage")}
              src={Logo}
              alt="logo"
              style={{
                maxWidth: "120px",
                marginLeft: !sm ? "20px" : "",
                cursor: "pointer",
              }}
            />
          </Col>
          {lg && (
            <Col>
              <Button
                onClick={() => {
                  message.loading("Logging Out...", 10);
                  window.location = `https://amity.onelogin.com/oidc/2/logout?post_logout_redirect_uri=https://amity.nsw.edu.au&id_token_hint=${localStorage.getItem(
                    "token"
                  )}`;
                  localStorage.clear();
                }}
                type="primary"
                icon={<LogoutOutlined />}
              >
                Log Out
              </Button>
            </Col>
          )}
        </Row>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsible
          collapsed={isSider}
          theme="light"
          className={!lg ? "mobil-sider" : "desktop-sider"}
        >
          <br />
          <Tag
            color="blue"
            style={{
              width: "calc(100% - 40px)",
              textAlign: "center",
              margin: "5px 20px 0 20px",
            }}
          >
            Admin Menu
          </Tag>
          <Menu
            className="amenu"
            onSelect={() => {
              !lg && setIsSider(true);
            }}
            theme="light"
            mode="inline"
            defaultSelectedKeys={[location.pathname.split("/").pop()]}
          >
            <Menu.Item key="adminpage" icon={<DashboardOutlined />}>
              <Link to="/admin/dashboard">Dashboard</Link>
            </Menu.Item>
            <SubMenu
              key="submenu"
              icon={<UnorderedListOutlined />}
              title="Definitions"
            >
              <Menu.Item key="campuses" icon={<HomeOutlined />}>
                <Link to="/admin/campuses">Campuses</Link>
              </Menu.Item>
              <Menu.Item key="faculties" icon={<BankOutlined />}>
                <Link to="/admin/faculties">Faculties</Link>
              </Menu.Item>
              <Menu.Item key="positions" icon={<ApartmentOutlined />}>
                <Link to="/admin/positions">Positions</Link>
              </Menu.Item>
              <Menu.Item key="schoollevels" icon={<ClusterOutlined />}>
                <Link to="/admin/schoollevels">School Levels</Link>
              </Menu.Item>
              <Menu.Item key="workingdays" icon={<CalendarOutlined />}>
                <Link to="/admin/workingdays">Working Days</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="staff" icon={<UsergroupAddOutlined />}>
              <Link to="/admin/staff">Staff</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content
          style={{
            marginTop: lg ? "75px" : undefined,
            padding: "10px",
            borderLeft: "1px solid #ddd",
            minHeight: "calc(100vh - 150px)",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <Footer className="footer">
        <div>
          <p className="text-small text-gray-400 text-center">
            <span className="text-emerald-600 font-semibold">
              &copy; Amity College
            </span>
            {", "}2022 . All rights reserved. <br />
            Developed with <HeartOutlined /> React
          </p>
        </div>
      </Footer>
    </Layout>
  );
};

export default AdminLayout;

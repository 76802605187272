import { notification } from "antd";
import Axios from "axios";
import nProgress from "nprogress";
import { axHistory } from "./axHistory";
var refreshTokenCached;
function refreshToken() {
  if (refreshTokenCached) return refreshTokenCached;

  refreshTokenCached = _refreshToken();

  return refreshTokenCached;

  function _refreshToken() {
    return fetch("https://amity.onelogin.com/oidc/2/token", {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body:
        "client_id=" +
        process.env.REACT_APP_ONELOGIN_CLIENT_ID +
        "&grant_type=refresh_token&refresh_token=" +
        encodeURIComponent(localStorage.getItem("refresh_token")),
    })
      .then((a) =>
        a.status !== 200 ? a.json().then(Promise.reject) : a.json()
      )
      .finally(() => {
        refreshTokenCached = null;
      });
  }
}
nProgress.configure({ showSpinner: false, trickleSpeed: 100 });
var queryParams = new URLSearchParams(window.location.search);

var axios = Axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.interceptors.request.use(
  function (config) {
    nProgress.remove();
    nProgress.start();
    if (!config.url.startsWith("http")) {
      if (queryParams.get("key")) {
        config.headers.Authorization =
          'SchoolBox key="' +
          queryParams.get("key") +
          '" user="' +
          queryParams.get("user") +
          '" id="' +
          queryParams.get("id") +
          '" time="' +
          queryParams.get("time") +
          '"';
      } else {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    nProgress.done();
    return response;
  },
  function (error) {
    nProgress.done();

    // 402 Payment Required / 3Ds secure needed
    if (error && error.response && error.response.status === 402) {
      return error.response; // return response;
    }

    if (
      [401, 402, 403].findIndex(
        (a) => a === parseInt(error.response.status)
      ) !== -1
    ) {
      return refreshToken()
        .catch((a) => {
          notification.destroy();
          notification.error({
            message: "Token error or expired!",
            description: "Logging in again.",
          });

          // console.error("token hatasi", a);
          localStorage.removeItem("token");
          localStorage.removeItem("refresh_token");
          axHistory.push("/login", { from: window.location.pathname });
          return Promise.reject(a);
        })
        .then(function (data) {
          if (data.id_token) {
            localStorage.setItem("token", data.id_token);
          }

          if (data.refresh_token) {
            localStorage.setItem("refresh_token", data.refresh_token);
          }
          error.config.headers["Authorization"] = "Bearer " + data.id_token;
          error.config.baseURL = undefined;
          return axios.request(error.config);
        });
    } else {
      console.error("axios error:", error.response);
      notification.error({
        message: "Error!",
        description: error.response.data.message
          ? `Error message: ${error.response.data.message}`
          : "Try again later",
      });
      // localStorage.clear();
      // axHistory.push("/login");
    }
    return Promise.reject(error);
  }
);
export { axios };

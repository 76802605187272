import { axios } from "./HttpClient";

export const AdminService = {
  loginByCode: (values) => {
    return axios.post("/login", values);
  },
  newCampus: (values) => {
    return axios.post("/campus", values);
  },
  getCampuses: () => {
    return axios.get("/campus");
  },
  newFaculty: (values) => {
    return axios.post("/faculty", values);
  },
  getFaculties: () => {
    return axios.get("/faculty");
  },
  newPosition: (values) => {
    return axios.post("/position", values);
  },
  getPositions: () => {
    return axios.get("/position");
  },
  newSchoolLevel: (values) => {
    return axios.post("/schoollevel", values);
  },
  getSchoolLevels: () => {
    return axios.get("/schoollevel");
  },
  newWorkingDay: (values) => {
    return axios.post("/workingday", values);
  },
  getWorkingDays: () => {
    return axios.get("/workingday");
  },
  newStaff: (values) => {
    return axios.post("/staff", values);
  },
  getStaffs: () => {
    return axios.get("/staff");
  },
  getFormData: () => {
    return axios.get("/staff/formdata");
  },
  login: (values) => {
    return axios.post("/login", values);
  },
  getExcel: () => {
    return axios.get("students/excel", { responseType: "blob" });
  },
  getStaffDetails: (id) => {
    return axios.get("/staff/" + id);
  },
  getMe: () => {
    return axios.get("/validation/me");
  },
  getMyFormData: () => {
    return axios.get("staff/myformdata");
  },
  getReports: () => {
    return axios.get("validation/reports");
  },
  deleteFaculty: (id) => {
    return axios.delete("/faculty/" + id);
  },
  UpdateFaculty: (values) => {
    return axios.put("/faculty/" + values.id, { name: values.name });
  },
  deletePosition: (id) => {
    return axios.delete("/position/" + id);
  },
  UpdatePosition: (values) => {
    return axios.put("/position/" + values.id, { name: values.name });
  },
  getGroupReports: () => {
    return axios.get("/reports");
  },
  getZoomLink: (email) => {
    return axios.get("/staff/getzoomlink?email=" + email);
  },
  schoolBoxTest: (key, time, id, user) => {
    return axios.get(
      "/dbset?key=" + key + "&time=" + time + "&id=" + id + "&user=" + user
    );
  },
  getLastLogins: () => {
    return axios.get("/validation/lastlogins");
  },
};

import { HeartOutlined } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Footer } from "antd/lib/layout/layout";
import React from "react";

const FooterBar = () => {
  const { lg } = useBreakpoint();
  return (
    <div>
      {" "}
      <Footer className="footer">
        <div>
          <p className="text-gray-400 text-center mb-0">
            <span className="text-sky-500 font-semibold text-sm">
              &copy; Amity College
            </span>
            <span className="text-sm">
              {", "}2022 . All rights reserved. {!lg && <br />}Developed by
              Amity ICT with <HeartOutlined /> React
            </span>
          </p>
        </div>
      </Footer>
    </div>
  );
};

export default FooterBar;

import {
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Divider,
  Table,
  Input,
  Space,
  Button,
  Popconfirm,
  Tooltip,
  notification,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const Faculties = () => {
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [faculties, setFaculties] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [newName, setNewName] = useState("");
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getFaculties().then((data) => {
        setFaculties(data.data);
      });
    }
  }, [initialRender]);

  const onFinish = (values) => {
    console.log(values);
    AdminService.newFaculty(values).then((data) => {
      setInitialRender(true);
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
  };

  const deleteFaculty = (id) => {
    console.log(id);
    AdminService.deleteFaculty(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Faculty deleted!",
      });
      setInitialRender(true);
    });
  };
  const editFaculty = (item) => {
    console.log(item);
    setEditItem(item);
    setNewName(item.name);
    setEditModal(true);
  };
  const goEdit = () => {
    setEditModal(false);
    console.log(newName);
    AdminService.UpdateFaculty({ id: editItem.id, name: newName }).then(
      (data) => {
        notification.success({
          message: "Success!",
          description: "Faculty updated!",
        });
        setInitialRender(true);
      }
    );
  };

  const columns = [
    {
      title: "Faculty Name",
      dataIndex: "name",
    },
    {
      title: "Actions",
      render: (item) => (
        <Space>
          <Tooltip title="Delete Faculty" placement="left" key={item.id}>
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => deleteFaculty(item.id)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Update Faculty" placement="left" key={item.id}>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => editFaculty(item)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="max-w-6xl p-2 mx-auto">
      {editItem && (
        <Modal
          visible={editModal}
          onCancel={() => setEditModal(false)}
          onOk={() => goEdit()}
        >
          <div>
            <p>Type new name</p>
            <Input
              placeholder="Type faculty name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
        </Modal>
      )}
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">Faculties</Divider>
          <Table
            rowKey="id"
            bordered
            size="small"
            dataSource={faculties}
            loading={faculties === null}
            columns={columns}
            className="lg:mr-12 border border-gray-200"
            pagination={false}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">Add new Faculty</Divider>
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            layout="vertical"
          >
            <Form.Item
              label="Faculty Name"
              name="name"
              rules={[{ required: true, message: "Faculty name is required!" }]}
            >
              <Input placeholder="Please type faculty name" />
            </Form.Item>

            <Form.Item className="mt-6">
              <Space>
                <Button
                  type="default"
                  htmlType="reset"
                  icon={<ClearOutlined />}
                >
                  Clear
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Faculties;

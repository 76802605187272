import { MenuFoldOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const Filters = () => {
  const {
    setCarPlate,
    campus,
    slevel,
    pos,
    fac,
    wd,
    scategory,
    setScategory,
    gender,
    teaching,
    setTeaching,
    carPlate,
    setCampus,
    setGender,
    setIsTeaching,
    setIsSider,
    setSlevel,
    setPos,
    setFac,
    setWd,
  } = useContext(GlobalContext);
  const [campuses, setCampuses] = useState([]);
  const [localCampuses, setLocalCampuses] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [positions, setPositions] = useState([]);
  const [schoolLevels, setSchoolLevels] = useState([]);
  const [scategories, setScategories] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);
  const [teachings, setTeachings] = useState([]);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getFormData().then((data) => {
        setCampuses(data.data.campuses);
        setFaculties(data.data.faculties);
        setTeachings(data.data.teachings);
        setPositions(data.data.positions);
        setSchoolLevels(data.data.schoolLevels);
        setWorkingDays(data.data.workingDays);
        setLocalCampuses(data.data.localCampuses);
        setScategories(data.data.scategories);

        console.log(data.data);
      });
    }
  }, [initialRender]);

  const onCarPlate = (c) => {
    setCarPlate(c);
  };
  const campusChanged = (v) => {
    setCampus(v);
    console.log(v);
  };
  const scatChanged = (v) => {
    setScategory(v);
  };
  const teachingChanged = (v) => {
    console.log("aa", v);
    setTeaching(v);
  };
  const posChanged = (v) => {
    setPos(v);
  };
  const facChanged = (v) => {
    setFac(v);
  };
  const wdChanged = (v) => {
    console.log(v);
    setWd(v);
  };
  const genderChanged = (v) => {
    setGender(v);
    console.log(v);
  };
  const isteachingChanged = (v) => {
    console.log(v);
    setIsTeaching(v);
  };
  // const plateChanged = (v) => {
  //   setCarPlate(v.target.value);
  // };
  return (
    <div>
      <div className="space-y-2 mt-2">
        <div>
          <span className="text-sm">Main Campus</span>
          <Select
            showSearch
            placeholder="Select campus"
            onChange={campusChanged}
            className="w-full"
            allowClear
            value={campus}
          >
            {campuses.map((c) => (
              <Select.Option key={c.id} value={c.name}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        {/* <div>
          <span className="text-sm">School Level</span>
          <Select
            placeholder="Select level"
            onChange={levelChanged}
            className="w-full"
            allowClear
            value={slevel}
          >
            {schoolLevels.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </div> */}
        <div>
          <span className="text-sm">Form</span>
          <Select
            showSearch
            placeholder="Select form"
            onChange={teachingChanged}
            className="w-full"
            allowClear
            value={teaching}
          >
            {teachings.map((c) => (
              <Select.Option key={c.id} value={c.name}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        {/* <div>
          <span className="text-sm">Category</span>
          <Select
            showSearch
            placeholder="Select category"
            onChange={scatChanged}
            className="w-full"
            allowClear
            value={scategory}
          >
            {scategories.map((c) => (
              <Select.Option key={c.id} value={c.name}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </div> */}
        <div>
          <span className="text-sm">Position</span>
          <Select
            showSearch
            placeholder="Select position"
            onChange={posChanged}
            className="w-full"
            allowClear
            value={pos}
          >
            {positions.map((c) => (
              <Select.Option key={c.id} value={c.name}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <span className="text-sm">Faculties</span>
          <Select
            showSearch
            placeholder="Select faculty"
            onChange={facChanged}
            className="w-full"
            allowClear
            value={fac}
          >
            {faculties.map((c) => (
              <Select.Option key={c.id} value={c.name}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <span className="text-sm">Working Days</span>
          <Select
            showSearch
            placeholder="Select day"
            onChange={wdChanged}
            className="w-full"
            allowClear
            value={wd}
          >
            {workingDays.map((c) => (
              <Select.Option key={c.id} value={c.name}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div>
          <span className="text-sm">Gender</span>
          <Select
            showSearch
            placeholder="Select gender"
            onChange={genderChanged}
            className="w-full"
            allowClear
            value={gender}
          >
            {GlobalVariables.genders.map((c) => (
              <Select.Option key={c.id} value={c.name}>
                {c.name === "M" ? "Male" : "Female"}
              </Select.Option>
            ))}
          </Select>
        </div>
        {/* <div>
          <span className="text-sm">Is Teaching?</span>
          <Select
            placeholder="Please select"
            onChange={isteachingChanged}
            className="w-full"
            allowClear
            value={isTeaching}
          >
            {GlobalVariables.yesnoselect.map((c) => (
              <Select.Option key={c.id} value={c.id}>
                {c.name}
              </Select.Option>
            ))}
          </Select>
        </div> */}
        {/* <div>
          <span className="text-sm">Car Number Plate</span>
          <Input.Search
            enterButton
            onSearch={onCarPlate}
            value={carPlate}
            onChange={plateChanged}
          />
        </div> */}
        <Button
          icon={<MenuFoldOutlined />}
          block
          type="primary"
          onClick={() => setIsSider(true)}
        >
          Close Filter Panel
        </Button>
      </div>
    </div>
  );
};

export default Filters;

import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "src/Loader";
import { AdminService } from "../resources/AdminService";

function useQueryParams() {
  const location = useLocation();
  return useMemo(
    () =>
      Array.from(new URLSearchParams(location.search).entries()).reduce(
        (a, [key, value]) => ({ ...a, [key]: value }),
        {}
      ),
    [location.search]
  );
}

const LoginLayout = () => {
  const router = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const { code } = useQueryParams();

  useEffect(() => {
    //localStorage.clear();
    console.error("error", searchParams.get("error"));

    if (searchParams.get("error") === "access_denied") {
      alert("Your user is not authorized to use this platform");
      window.open("https://amity.nsw.edu.au", "_self");
      return;
    }
    if (!code) {
      window.location =
        "https://amity.onelogin.com/oidc/2/auth?client_id=" +
        process.env.REACT_APP_ONELOGIN_CLIENT_ID +
        "&scope=openid email name&response_type=code&redirect_uri=" +
        encodeURIComponent(window.location.origin + "/login") +
        "&code_challenge_method=S256&code_challenge=" +
        encodeURIComponent("2N5KaWqLSJOd8s39ifdcBKXOrAe3lu31-i-WzU3EeOY");
    } else {
      fetch("https://amity.onelogin.com/oidc/2/token", {
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        body:
          "client_id=" +
          process.env.REACT_APP_ONELOGIN_CLIENT_ID +
          "&grant_type=authorization_code&code=" +
          encodeURIComponent(code) +
          "&redirect_uri=" +
          encodeURIComponent(window.location.origin + "/login") +
          "&code_verifier=K.T5Sy3-d_fb3zaJaLsoaDTXLVPMnhLeIFMTc1psvYM8XINBe4W9zFWtb2C31YhPSl91sfYPb9RhMoXpTfde-AF-fiejx9kmjY._8Q5qPmPR~L2nsVV0am7J2gG.hhm9",
      })
        .then((a) => a.json())
        .then((a) => {
          console.log(a);
          a.id_token !== undefined && localStorage.setItem("token", a.id_token);
          a.refresh_token !== undefined &&
            localStorage.setItem("refresh_token", a.refresh_token);
          AdminService.getMe().then((data) => {
            console.log("Me: ", data.data);
            localStorage.setItem("user", JSON.stringify(data.data));
            if (data.data.isRegistered) {
              router("/list", { replace: true });
            } else {
              router("/home/myprofile?firsttime=true");
            }
          });
        })
        .catch((e) => {
          localStorage.clear();
          router("/login");
        });
    }
  }, [code]);

  return (
    <div className="login-page">
      <Loader />
    </div>
  );
};
export default LoginLayout;

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Input,
  Layout,
  Menu,
  message,
  Row,
  Tag,
} from "antd";
import {
  HeartOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useNavigate, Outlet } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Filters from "src/components/list/Filters";
import MyCard from "./MyCard";
import { GlobalContext } from "src/context/GlobalContext";

const ListLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg, sm } = useBreakpoint();
  const { isSider, setIsSider } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);

  return (
    <Layout>
      <Header
        style={{
          height: "75px",
          lineHeight: "75px",
          position: lg ? "fixed" : "unset",
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
          borderBottom: "1px solid #ccc",
          boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <Row justify={!sm ? "start" : "space-between"} align="middle">
          <Col>
            <Button
              icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size="large"
              onClick={() => setIsSider(!isSider)}
            />
          </Col>
          <Col>
            <img
              onClick={() => navigate("/")}
              src={Logo}
              alt="logo"
              style={{
                maxWidth: "120px",
                marginLeft: !sm ? "20px" : "",
                cursor: "pointer",
              }}
            />
          </Col>
          {lg && (
            <Col>
              <Button
                onClick={() => {
                  message.loading("Logging Out...", 10);
                  window.location = `https://amity.onelogin.com/oidc/2/logout?post_logout_redirect_uri=https://amity.nsw.edu.au&id_token_hint=${localStorage.getItem(
                    "token"
                  )}`;
                  localStorage.clear();
                }}
                type="primary"
                icon={<LogoutOutlined />}
              >
                Log Out
              </Button>
            </Col>
          )}
        </Row>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsible
          collapsed={isSider}
          onCollapse={() => setIsSider(!isSider)}
          theme="light"
          className={!lg ? "mobil-sider shadow-xl" : "desktop-sider "}
        >
          <div className="p-2">
            <div className="mt-4">
              <MyCard />
            </div>
            <Tag
              color="blue"
              className="text-base font-semibold w-full text-center !mt-5 "
            >
              Filter By
            </Tag>
            <Filters />
          </div>
        </Sider>
        <Content
          style={{
            marginTop: lg ? "75px" : undefined,
            padding: "15px",

            borderLeft: "1px solid #ddd",
            minHeight: "calc(100vh - 130px)",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <Footer className="footer">
        <div>
          <p className="text-gray-400 text-center mb-0">
            <span className="text-sky-500 font-semibold text-sm">
              &copy; Amity College
            </span>
            <span className="text-sm">
              {", "}2022 . All rights reserved. Developed by Amity ICT with{" "}
              <HeartOutlined /> React
            </span>
          </p>
        </div>
      </Footer>
    </Layout>
  );
};

export default ListLayout;

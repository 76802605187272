import React from "react";
import Logo from "./assets/images/logo.png";

const Loader = () => {
  return (
    <div className="loader-bg space-y-12">
      <img src={Logo} alt="logo" width="250px" />
      <br />

      <div className="sk-folding-cube ">
        <div className="sk-cube1 sk-cube"></div>
        <div className="sk-cube2 sk-cube"></div>
        <div className="sk-cube4 sk-cube"></div>
        <div className="sk-cube3 sk-cube"></div>
      </div>
    </div>
  );
};

export default Loader;

import {
  AppstoreOutlined,
  ClearOutlined,
  LoginOutlined,
  MenuOutlined,
  PieChartOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Empty,
  Input,
  List,
  Modal,
  PageHeader,
  Radio,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";
import Female from "../../assets/images/female.jpg";
import Male from "../../assets/images/male.jpg";
import DetailsModal from "./DetailsModal";

const StaffList = () => {
  const { lg } = useBreakpoint();
  const [staff, setStaff] = useState(null);
  const [seaarchResult, setSeaarchResult] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [aramaText, setAramaText] = useState();
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedStaff, setselectedStaff] = useState(null);
  const [grid, setGrid] = useState(true);
  const { Search } = Input;
  const navigate = useNavigate();
  const {
    carPlate,
    campus,
    gender,
    isTeaching,
    teaching,
    scategory,
    setScategory,
    setTeaching,
    slevel,
    pos,
    fac,
    wd,
    setZoom,
    setCampus,
    setIsSider,
    setSlevel,
    setPos,
    setFac,
    setWd,
    setGender,
    setIsTeaching,
    setCarPlate,
  } = useContext(GlobalContext);

  useEffect(() => {
    selectedStaff && setDetailsModalVisible(true);
  }, [selectedStaff]);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      message.loading("Loading...", 0);
      AdminService.getStaffs().then((data) => {
        console.log(data.data);
        setStaff(data.data);
        setSeaarchResult(data.data);
        message.destroy();
      });
    }
  }, [initialRender]);

  const campusFilter = (result) => {
    if (campus) {
      return result.filter((s) => {
        return s.campus === campus;
      });
    } else {
      return result;
    }
  };
  const slevelFilter = (result) => {
    if (slevel) {
      return result.filter((s) => {
        return s.schoolLevels.findIndex((a) => a.id === slevel) !== -1;
      });
    } else {
      return result;
    }
  };

  const positionFilter = (result) => {
    console.log("pos::", pos);
    if (pos) {
      return result.filter((s) => {
        if (pos === "Not Selected" && s.positions.length === 0) {
          return s;
        } else {
          return s.positions.findIndex((a) => a.name === pos) !== -1;
        }
      });
    } else {
      return result;
    }
  };
  const teachingFilter = (result) => {
    if (teaching) {
      return result.filter((s) => {
        return s.category === teaching;
      });
    } else {
      return result;
    }
  };
  const scategoryFilter = (result) => {
    if (scategory) {
      return result.filter((s) => {
        return s.staffCategory === scategory;
      });
    } else {
      return result;
    }
  };
  const facultyFilter = (result) => {
    if (fac) {
      return result.filter((s) => {
        return (
          s.faculties !== null &&
          s.faculties.findIndex((a) => a.name === fac) !== -1
        );
      });
    } else {
      return result;
    }
  };
  const workingDaysFilter = (result) => {
    if (wd) {
      return result.filter((s) => {
        return (
          s.workingDays !== null &&
          s.workingDays.findIndex((a) => a.name === wd) !== -1
        );
      });
    } else {
      return result;
    }
  };

  const genderFilter = (result) => {
    if (gender) {
      return result.filter((s) => {
        return s.gender === gender;
      });
    } else {
      return result;
    }
  };
  const isteachingFilter = (result) => {
    if (isTeaching) {
      let kar = isTeaching === 1 ? true : false;
      return result.filter((s) => {
        return s.isTeaching === kar;
      });
    } else {
      return result;
    }
  };
  const plateFilter = (result) => {
    if (carPlate) {
      return result.filter((s) => {
        return (
          s.carplate1?.toLowerCase().includes(carPlate?.toLowerCase()) ||
          s.carplate2?.toLowerCase().includes(carPlate?.toLowerCase())
        );
      });
    } else {
      return result;
    }
  };

  useEffect(() => {
    setAramaText(null);
    let result = staff;
    result = campusFilter(result);
    result = slevelFilter(result);
    result = positionFilter(result);
    result = facultyFilter(result);
    result = workingDaysFilter(result);
    result = genderFilter(result);
    result = isteachingFilter(result);
    result = teachingFilter(result);
    //result = scategoryFilter(result);
    result = plateFilter(result);
    setSeaarchResult(result);
  }, [
    campus,
    gender,
    isTeaching,
    teaching,
    //  scategory,
    staff,
    slevel,
    pos,
    wd,
    fac,
    //  carPlate,
  ]);

  //   useEffect(() => {
  //     staff &&
  //       setSeaarchResult(
  //         staff.filter((s) => {
  //           return (
  //             s.carplate1.toLowerCase().includes(carPlate.toLowerCase()) ||
  //             s.carplate2.toLowerCase().includes(carPlate.toLowerCase())
  //           );
  //         })
  //       );
  //   }, [carPlate]);

  const onSearch = (text) => {
    console.log(text.target.value);

    if (text.target.value !== undefined) {
      text = text.target.value;
      setAramaText(text);
      setSeaarchResult(
        staff.filter((s) => {
          return (
            s.firstname.toLowerCase().includes(text.toLowerCase()) ||
            s.lastname.toLowerCase().includes(text.toLowerCase()) ||
            (s.firstname + " " + s.lastname)
              .toLowerCase()
              .includes(text.toLowerCase())
          );
        })
      );
    } else {
      clearAllFilters();
    }
    if (text === "") {
      clearAllFilters();
    }
  };

  const clearAllFilters = () => {
    setAramaText(null);
    setCampus(null);
    setSlevel(null);
    setPos(null);
    setFac(null);
    setWd(null);
    setGender(null);
    setIsTeaching(null);
    setTeaching(null);
    setScategory(null);
    setCarPlate(null);
    setSeaarchResult(staff);
    // onSearch();
  };

  const closeModal = () => {
    console.log("set staff null");
    setselectedStaff(null);
    setZoom(null);

    setDetailsModalVisible(false);
  };
  const customizeEmpty = () => (
    <Empty
      className="bg-white p-2 lg:p-4 border border-gray-300"
      image={Empty.PRESENTED_IMAGE_DEFAULT}
      imageStyle={{
        height: 60,
      }}
      description={
        <span className="text-gray-400">
          No staff found matching your filter criteria.
        </span>
      }
    >
      <Button type="primary" onClick={() => clearAllFilters()}>
        {" "}
        Clear All Filters
      </Button>
    </Empty>
  );

  const columns = [
    {
      title: "First name",
      dataIndex: "firstname",
    },
    {
      title: "Last name",
      dataIndex: "lastname",
    },
    {
      title: "Campus",
      dataIndex: "campus",
    },
    {
      title: "Form",
      render: (item) =>
        item.category !== " Not Selected" && (
          <Tag
            color={
              item.categoryId === "STFSU"
                ? "green"
                : item.categoryId === "STFTE"
                ? "magenta"
                : "orange"
            }
          >
            {item.category}
          </Tag>
        ),
    },
    {
      title: "Category",
      dataIndex: "staffCategory",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Action",
      render: (item) => (
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          onClick={() => setselectedStaff(item.id)}
        >
          Details
        </Button>
      ),
    },
  ];
  const radioChanged = (v) => {
    setGrid(v.target.value);
  };

  return (
    seaarchResult && (
      <div>
        <Modal
          width="100%"
          style={{
            top: "10px",
            maxWidth: "1100px",
          }}
          visible={detailsModalVisible}
          onCancel={() => closeModal()}
          onOk={() => closeModal()}
          footer={false}
        >
          <DetailsModal id={selectedStaff} modalVisible={closeModal} />
        </Modal>

        {/* <Search
          value={aramaText}
          onChange={onSearch}
          className="max-w-md mb-4 "
          placeholder="Input search text"
          allowClear
          // enterButton={`${seaarchResult.length}`}

          //  onSearch={onSearch}
        /> */}
        {/* <Button className="ml-1" type="primary" icon={<UserOutlined />}>
          {seaarchResult.length} staff listed
        </Button>
        {lg && (
          <Radio.Group
            className="!z-0 !ml-2 !mr-1"
            defaultValue={true}
            buttonStyle="solid"
            onChange={radioChanged}
          >
            <Radio.Button value={true} className="!z-0">
              <AppstoreOutlined />
            </Radio.Button>
            <Radio.Button value={false} className="!z-0">
              <MenuOutlined />
            </Radio.Button>
          </Radio.Group>
        )} */}
        {/* <Button
          className="ml-1 !bg-gradient-to-b !from-white !to-gray-50 mb-2"
          type="primary"
          ghost
          icon={<ClearOutlined />}
          onClick={clearAllFilters}
        >
          Clear All Filters
        </Button>
        <br /> */}

        <PageHeader
          className="site-page-header-responsive"
          onBack={null}
          title={
            (campus ||
              slevel ||
              pos ||
              fac ||
              wd ||
              gender ||
              teaching ||
              scategory ||
              isTeaching ||
              carPlate) &&
            !aramaText && (
              <>
                <Space wrap>
                  <span className="text-base">Active Filters:</span>
                  {campus && (
                    <Tag
                      closable
                      className="bg-white"
                      onClose={() => setCampus(null)}
                    >
                      {campus}
                    </Tag>
                  )}
                  {slevel && (
                    <Tag
                      closable
                      className="bg-white"
                      onClose={() => setSlevel(null)}
                    >
                      School Level
                    </Tag>
                  )}
                  {pos && (
                    <Tag
                      closable
                      className="bg-white"
                      onClose={() => setPos(null)}
                    >
                      {pos}
                    </Tag>
                  )}
                  {fac && (
                    <Tag
                      closable
                      className="bg-white"
                      onClose={() => setFac(null)}
                    >
                      {fac}
                    </Tag>
                  )}
                  {wd && (
                    <Tag
                      closable
                      className="bg-white"
                      onClose={() => setWd(null)}
                    >
                      {wd}
                    </Tag>
                  )}
                  {gender && (
                    <Tag
                      closable
                      className="bg-white"
                      onClose={() => setGender(null)}
                    >
                      {gender === "M" ? "Male" : "Female"}
                    </Tag>
                  )}
                  {teaching && (
                    <Tag
                      closable
                      className="bg-white"
                      onClose={() => setTeaching(null)}
                    >
                      {teaching}
                    </Tag>
                  )}
                  {scategory && (
                    <Tag
                      closable
                      className="bg-white"
                      onClose={() => setScategory(null)}
                    >
                      {scategory}
                    </Tag>
                  )}
                  {isTeaching && (
                    <Tag
                      closable
                      className="bg-white"
                      onClose={() => setIsTeaching(null)}
                    >
                      Is Teaching
                    </Tag>
                  )}
                  {carPlate && (
                    <Tag
                      closable
                      className="bg-white"
                      onClose={() => setCarPlate(null)}
                    >
                      Car Plate
                    </Tag>
                  )}
                </Space>{" "}
                <br />
              </>
            )
          }
          // subTitle="This is a subtitle"
          extra={
            <Space wrap>
              <Search
                value={aramaText}
                onChange={onSearch}
                className="!w-full lg:!w-72  "
                placeholder="Input search text"
                allowClear
                enterButton
                // enterButton={`${seaarchResult.length}`}

                //  onSearch={onSearch}
              />
              <Button
                className="ml-1 bg-white"
                type="primary"
                ghost
                icon={<UserOutlined />}
              >
                {seaarchResult.length} staff listed
              </Button>
              <>
                {lg && (
                  <Radio.Group
                    className="!z-0 !ml-2 !mr-1"
                    defaultValue={true}
                    buttonStyle="solid"
                    onChange={radioChanged}
                  >
                    <Radio.Button value={true} className="!z-0">
                      <AppstoreOutlined />
                    </Radio.Button>
                    <Radio.Button value={false} className="!z-0">
                      <MenuOutlined />
                    </Radio.Button>
                  </Radio.Group>
                )}
              </>
              <Button
                //   className="!bg-gradient-to-b !from-white !to-gray-50 "
                type="primary"
                icon={<ClearOutlined />}
                onClick={clearAllFilters}
              >
                Clear All Filters
              </Button>

              <Button
                key="1"
                type="primary"
                icon={<PieChartOutlined />}
                onClick={() => {
                  setIsSider(true);
                  navigate("/list/reports");
                }}
              >
                Reports
              </Button>
              {(JSON.parse(localStorage.getItem("user")).email ===
                "syazici@amity.nsw.edu.au" ||
                JSON.parse(localStorage.getItem("user")).email ===
                  "aaltundal@amity.nsw.edu.au") && (
                <Button
                  key="11"
                  type="primary"
                  icon={<LoginOutlined />}
                  onClick={() => {
                    navigate("/list/lastlogins");
                  }}
                >
                  Last Logins
                </Button>
              )}
            </Space>
          }
        ></PageHeader>
        {grid && (
          <ConfigProvider renderEmpty={customizeEmpty}>
            <List
              loading={staff === null}
              grid={{
                gutter: 24,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 4,
                xxl: 5,
              }}
              dataSource={seaarchResult}
              renderItem={(item) => (
                <List.Item onClick={() => setselectedStaff(item.id)}>
                  <div className="bg-white border border-gray-300 hover:shadow-lg hover:cursor-pointer  ">
                    <Space>
                      <img
                        src={
                          item.photo
                            ? process.env.REACT_APP_API_BASEURL +
                              "photos/" +
                              item.id +
                              "/photo"
                            : item.gender === "M"
                            ? Male
                            : Female
                        }
                        alt="staff_img"
                        style={{
                          height: "96px",
                          width: "72px",
                          borderRight: "1px solid #ccc",
                        }}
                        className="object-cover"
                      />
                      <div>
                        <p className="font-bold text-sky-500 mb-0">
                          {item.firstname + " " + item.lastname}
                        </p>

                        <p className="mb-0 leading-8 text-sm">{item.campus}</p>
                        <p className="mb-0 leading-8 text-xs">
                          Staff ID:{" "}
                          <span className="font-semibold">{item.id}</span>
                        </p>
                        {/* <Tag color="blue">{item.staffCategory}</Tag> */}
                        {/* {item.category !== " Not Selected" ? (
                          <Space direction="vertical">
                            <Tag
                              color={
                                item.categoryId === "STFSU"
                                  ? "green"
                                  : item.categoryId === "STFTE"
                                  ? "magenta"
                                  : "orange"
                              }
                            >
                              {item.category}
                            </Tag>
                          </Space>
                        ) : (
                          ""
                        )} */}
                        {/* {item.positions &&
                        item.positions.length > 0 &&
                        item.positions.map((p) => (
                          <Tag key={p.id}>{p.name}</Tag>
                        ))} */}
                      </div>
                    </Space>
                  </div>
                </List.Item>
              )}
            />
          </ConfigProvider>
        )}
        {!grid && (
          <Table
            rowKey="id"
            pagination={{ pageSize: 50 }}
            dataSource={seaarchResult}
            size="small"
            bordered
            columns={columns}
          />
        )}
      </div>
    )
  );
};

export default StaffList;

import React, { createContext, useState } from "react";

const initialState = {};
export const GlobalContext = createContext(initialState);
export const GlobalContextProvider = ({ children }) => {
  const [isAffix, setIsAffix] = useState(false);
  const [carPlate, setCarPlate] = useState("");
  const [campus, setCampus] = useState(null);
  const [gender, setGender] = useState(null);
  const [isTeaching, setIsTeaching] = useState(null);
  const [teaching, setTeaching] = useState(null);
  const [scategory, setScategory] = useState(null);
  const [slevel, setSlevel] = useState(null);
  const [pos, setPos] = useState(null);
  const [fac, setFac] = useState(null);
  const [wd, setWd] = useState(null);
  const [isSider, setIsSider] = useState(false);
  const [zoom, setZoom] = useState(null);

  return (
    <GlobalContext.Provider
      value={{
        isAffix,
        setIsAffix,
        carPlate,
        setCarPlate,
        campus,
        setCampus,
        gender,
        isSider,
        setIsSider,
        setGender,
        scategory,
        setScategory,
        isTeaching,
        setIsTeaching,
        teaching,
        setTeaching,
        slevel,
        setSlevel,
        pos,
        setPos,
        fac,
        setFac,
        wd,
        setWd,
        zoom,
        setZoom,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

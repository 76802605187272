import { ArrowLeftOutlined } from "@ant-design/icons";
import { Bar, measureTextWidth, Pie } from "@ant-design/plots";
import { PageHeader, Button, Row, Col, Divider, message } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";

const GroupReports = () => {
  const { setIsSider } = useContext(GlobalContext);
  const [data, setData] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const { lg } = useBreakpoint();
  useEffect(() => {
    if (initialRender) {
      message.loading("Loading Reports...", 10);
      setInitialRender(false);
      AdminService.getGroupReports().then((data) => {
        setData(data.data);
        message.destroy();
      });
    }
  }, [initialRender]);
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }
  const configBar = {
    // isGroup: true,
    xField: "value",
    yField: "type",
    legend: false,
    //color: ["#ED4235", "#FBBD05", "#4285F6", "#62DAAB", "#34A856", "#A4549F"],
    /** 自定义颜色 */
    // color: ["#9967BD", "#FF9D50"],
    seriesField: "type",
    marginRatio: 0,

    label: {
      // 可手动配置 label 数据标签位置
      position: "middle",
      // 'left', 'middle', 'right'
      // 可配置附加的布局方法
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: "interval-adjust-position",
        }, // 数据标签防遮挡
        {
          type: "interval-hide-overlap",
        }, // 数据标签文颜色自动调整
        {
          type: "adjust-color",
        },
      ],
    },
  };
  const config = {
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} ¥`,
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : "Total";
          return renderStatistic(d, text, {
            fontSize: 28,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "32px",
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `${datum.value}`
            : `${data.reduce((r, d) => r + d.value, 0)}`;
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };

  return (
    <div className="container mx-auto">
      <PageHeader
        className="site-page-header-responsive "
        backIcon={
          <Button
            type="primary"
            ghost
            className="!bg-white "
            icon={<ArrowLeftOutlined />}
          >
            Back
          </Button>
        }
        onBack={() => {
          lg && setIsSider(false);
          window.history.back();
        }}
        title="Staff Reports"
      />
      {data && (
        <div className="space-y-6 ">
          <div className="bg-white p-6 border border-gray-300">
            <Divider>Campus Report</Divider>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="bg-white lg:p-12 lg:border-r border-gray-300"
              >
                <Pie {...config} data={data.campus} />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="bg-white lg:p-12 "
              >
                <Bar {...configBar} data={data.campus} />
              </Col>
            </Row>
          </div>
          <div className="bg-white p-6 border border-gray-300">
            <Divider>Form Report</Divider>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="bg-white lg:border-r border-gray-300"
              >
                <Pie {...config} data={data.form} />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="bg-white lg:p-12 "
              >
                <Bar {...configBar} data={data.form} />
              </Col>
            </Row>
          </div>
          <div className="bg-white p-6 border border-gray-300">
            <Divider>Category Report</Divider>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="bg-white lg:border-r border-gray-300"
              >
                <Pie {...config} data={data.category} />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="bg-white lg:p-12 "
              >
                <Bar {...configBar} data={data.category} />
              </Col>
            </Row>
          </div>
          <div className="bg-white p-6 border border-gray-300">
            <Divider>Gender Report</Divider>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="bg-white lg:border-r border-gray-300"
              >
                <Pie {...config} data={data.gender} />
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
                className="bg-white lg:p-12 "
              >
                <Bar {...configBar} data={data.gender} />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupReports;

import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const LastLogins = () => {
  const [logins, setLogins] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getLastLogins().then((data) => {
        console.log(data.data);
        setLogins(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("dddd, DD-MM-YY HH:mm"),
    },
  ];
  return (
    <div className="max-w-lg">
      <Table
        loading={logins === null}
        title={() => (
          <span className="text-base text-sky-500 font-medium">
            Last 100 Logins
          </span>
        )}
        size="small"
        bordered
        columns={columns}
        dataSource={logins}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
};

export default LastLogins;

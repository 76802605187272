import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import { Form, Row, Col, Divider, Table, Input, Space, Button } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const SchoolLevels = () => {
  const [form] = Form.useForm();
  const [initialRender, setInitialRender] = useState(true);
  const [slevels, setSlevels] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getSchoolLevels().then((data) => {
        setSlevels(data.data);
      });
    }
  }, [initialRender]);

  const onFinish = (values) => {
    console.log(values);
    AdminService.newSchoolLevel(values).then((data) => {
      setInitialRender(true);
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  const columns = [
    {
      title: "School Level",
      dataIndex: "name",
    },
  ];
  return (
    <div className="max-w-6xl p-2 mx-auto">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">School Levels</Divider>
          <Table
            rowKey="id"
            bordered
            size="small"
            dataSource={slevels}
            loading={slevels === null}
            columns={columns}
            className="lg:mr-12 border border-gray-200"
            pagination={false}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">Add new School Level</Divider>
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            layout="vertical"
          >
            <Form.Item
              label="School Level"
              name="name"
              rules={[{ required: true, message: "School level is required!" }]}
            >
              <Input placeholder="Please type school level" />
            </Form.Item>

            <Form.Item className="mt-6">
              <Space>
                <Button
                  type="default"
                  htmlType="reset"
                  icon={<ClearOutlined />}
                >
                  Clear
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default SchoolLevels;

import React from "react";
import "react-quill/dist/quill.snow.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.less";
import AdminPage from "./components/admin/AdminPage";
import Campuses from "./components/admin/Campuses";
import Faculties from "./components/admin/Faculties";
import GroupReports from "./components/admin/GroupReports";
import LastLogins from "./components/admin/LastLogins";
import Positions from "./components/admin/Positions";
import SchoolLevels from "./components/admin/SchoolLevels";
import Staff from "./components/admin/Staff";
import WorkingDays from "./components/admin/WorkingDays";
import HomePage from "./components/home/HomePage";
import SchoolBoxTest from "./components/home/SchoolBoxTest";
import MyProfile from "./components/list/MyProfile";
import StaffList from "./components/list/StaffList";
import AdminLayout from "./layouts/AdminLayout";
import HomeLayout from "./layouts/HomeLayout";
import ListLayout from "./layouts/ListLayout";
import LoginLayout from "./layouts/LoginLayout";
import NotFound from "./layouts/NotFound";
import { AdminRoute } from "./resources/PrivateRoute";

function App() {
  return (
    <Routes>
      <Route path="home" element={<HomeLayout />}>
        <Route path="myprofile" element={<MyProfile />} />
        <Route path="homepage" element={<HomePage />} />
        <Route path="schoolbox" element={<SchoolBoxTest />} />

        {/*   <Route path="contact" element={<Contact />} />

        <Route path="" element={<Navigate replace to="homepage" />} />
  */}
      </Route>
      <Route
        path="/"
        element={
          localStorage.getItem("user") ? (
            <Navigate replace to="/list" />
          ) : (
            <Navigate replace to="/login" />
          )
        }
      />
      <Route
        path="admin"
        element={
          <AdminRoute>
            <AdminLayout />
          </AdminRoute>
        }
      >
        <Route path="dashboard" element={<AdminPage />} />
        <Route path="campuses" element={<Campuses />} />
        <Route path="faculties" element={<Faculties />} />
        <Route path="positions" element={<Positions />} />
        <Route path="schoollevels" element={<SchoolLevels />} />
        <Route path="workingdays" element={<WorkingDays />} />
        <Route path="staff" element={<Staff />} />

        {/*  <Route path="newstaff" element={<NewStaffForm />} />*/}

        <Route path="" element={<Navigate replace to="dashboard" />} />
      </Route>
      <Route path="list" element={<ListLayout />}>
        <Route path="staff" element={<StaffList />} />
        <Route path="reports" element={<GroupReports />} />
        <Route path="lastlogins" element={<LastLogins />} />

        <Route path="" element={<Navigate replace to="staff" />} />
      </Route>
      <Route path="login" element={<LoginLayout />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;

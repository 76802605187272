import {
  CheckOutlined,
  MailTwoTone,
  PhoneTwoTone,
  VideoCameraTwoTone,
} from "@ant-design/icons";
import { Badge, Button, Col, Descriptions, Row, Spin, Tabs, Tag } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "src/context/GlobalContext";
import { AdminService } from "src/resources/AdminService";
import Female from "../../assets/images/female.jpg";
import Male from "../../assets/images/male.jpg";
import TimeTable from "./TimeTable";

const DetailsModal = ({ id, modalVisible }) => {
  const [item, setItem] = useState(null);
  const [timetable, setTimetable] = useState(null);
  const [zloading, setZloading] = useState(false);
  const { zoom, setZoom } = useContext(GlobalContext);
  const { TabPane } = Tabs;
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }
  useEffect(() => {
    setTimetable(null);
    AdminService.getStaffDetails(id).then((data) => {
      console.log("yeni", data.data);
      setTimetable(data.data.timetable);
      setItem(data.data.user);
    });
  }, [id]);
  const getZoomLink = () => {
    setZloading(true);
    AdminService.getZoomLink(item.email)
      .then((data) => {
        console.log(data.data);
        data.data === "" ? setZoom("Link not defined") : setZoom(data.data);
      })
      .finally(() => setZloading(false));
  };
  return (
    <Spin spinning={item === null}>
      {item && (
        <div>
          <Row gutter={[16, 16]}>
            <Col flex="196px">
              <Badge.Ribbon color="orange" text={id}>
                <img
                  src={
                    item.photo
                      ? process.env.REACT_APP_API_BASEURL +
                        "photos/" +
                        item.id +
                        "/mediumphoto"
                      : item.gender === "M"
                      ? Male
                      : Female
                  }
                  alt="staff_img"
                  style={{
                    height: "240px",
                    width: "180px",
                    border: "2px solid #ccc",
                  }}
                  className="object-cover"
                />
              </Badge.Ribbon>
            </Col>
            <Col flex="auto">
              <p className="text-3xl text-sky-500 font-black mb-1">
                {`${item.firstname} ${item.middlename ? item.middlename : ""} ${
                  item.lastname
                }`}
              </p>
              <div>
                {item.positions.map((p) => (
                  <Tag key={p.id} color="blue">
                    {p.name}
                  </Tag>
                ))}
              </div>
              <br />
              <Row gutter={[8, 8]} align="middle">
                <Col flex="60px">
                  <MailTwoTone style={{ fontSize: "32px", opacity: 0.5 }} />
                </Col>
                <Col flex="auto">
                  <div>
                    <span>E-Mail</span>
                    <br />
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location = "mailto:" + item.email;
                        e.preventDefault();
                      }}
                    >
                      {item.email}
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row gutter={[8, 8]} align="middle" className="mt-2">
                <Col flex="60px">
                  <PhoneTwoTone style={{ fontSize: "32px", opacity: 0.5 }} />
                </Col>
                <Col flex="auto">
                  <div>
                    <span>Office Phone Number</span>
                    <br />
                    <span className="text-sky-600 text-lg">
                      {item.officePhone ? item.officePhone : "-"}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row gutter={[8, 8]} align="middle" className="mt-2">
                <Col flex="60px">
                  <VideoCameraTwoTone
                    style={{ fontSize: "32px", opacity: 0.5 }}
                  />
                </Col>
                <Col flex="auto">
                  <div className="space-y-2">
                    <span>Call via Zoom</span>
                    <br />
                    <Button
                      size="small"
                      loading={zloading}
                      type={zoom ? "primary" : "default"}
                      onClick={() => {
                        zoom && zoom !== "Link not defined"
                          ? window.open(zoom)
                          : zoom !== "Link not defined" && getZoomLink();
                      }}
                    >
                      {zoom ? zoom : "Get Zoom Link"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Descriptions
            title="School/Work Details"
            bordered
            column={1}
            className="mt-2"
            size="small"
          >
            <Descriptions.Item label="Main Campus">
              {item.campus}
            </Descriptions.Item>
            <Descriptions.Item label="Office/Staff Room Number">
              {
                <span className="text-sky-600 text-lg">
                  {item.officeRoomNumber ? item.officeRoomNumber : " - "}/
                  {item.staffRoomNumber ? item.staffRoomNumber : " - "}
                </span>
              }
            </Descriptions.Item>
            <Descriptions.Item label="Form">{item.category}</Descriptions.Item>
            {/* <Descriptions.Item label="Category">
              {item.staffCategory}
            </Descriptions.Item> */}
            {item.positions && (
              <Descriptions.Item label="Positions">
                {item.positions.map((s) => (
                  <Tag color="blue" key={s.id}>
                    {s.name}
                  </Tag>
                ))}
              </Descriptions.Item>
            )}
            {item.faculties && (
              <Descriptions.Item label="Faculties">
                {item.faculties.map((s) => (
                  <Tag color="purple" key={s.id}>
                    {s.name}
                  </Tag>
                ))}
              </Descriptions.Item>
            )}
            {/* <Descriptions.Item label="Teaching Staff">
              {item.isTeaching ? "Yes" : "No"}
            </Descriptions.Item> */}
            {item.returnDate && (
              <Descriptions.Item label="Return Date">
                {moment(item.returnDate).format("dddd, DD-MM-YY")}
              </Descriptions.Item>
            )}
          </Descriptions>
          <div>
            {timetable && timetable.length > 0 && (
              <>
                <p className="text-base font-bold text-sky-500 mt-4">
                  Timetable
                </p>

                <Tabs type="card">
                  {timetable.map((t) => (
                    <TabPane
                      tab={t.campus + "/" + t.timetableGroup}
                      key={t.campus + "/" + t.timetableGroup}
                    >
                      <TimeTable db={t} />
                    </TabPane>
                  ))}
                </Tabs>
              </>
            )}
          </div>
          {item.workingDays && (
            <Descriptions
              title="Working Days & Campuses"
              bordered
              column={1}
              className="mt-6"
              size="small"
            >
              {item.workingDays.map((s) => (
                <Descriptions.Item key={s.id} label={s.name}>
                  {s.campus}
                </Descriptions.Item>
              ))}
            </Descriptions>
          )}
          {/*    <Descriptions
            title="Personal Details"
            bordered
            column={1}
            className="mt-6"
            size="small"
          >
            <Descriptions.Item label="Allergies">
              {item.allergies}
            </Descriptions.Item>

           <Descriptions.Item label="Car 1 Number Plate">
              {item.carplate1}
            </Descriptions.Item>
            <Descriptions.Item label="Car 2 Number Plate">
              {item.carplate2}
            </Descriptions.Item> 
          </Descriptions>*/}
          <br />
          {item.info && (
            <>
              {" "}
              <p className="text-base font-bold text-sky-500 mb-1">
                Personal Info
              </p>
              <div
                dangerouslySetInnerHTML={createMarkup(item.info)}
                className="border border-gray-200 p-2"
              />
              <br />
            </>
          )}

          <div className="justify-end flex">
            <Button
              icon={<CheckOutlined />}
              type="primary"
              onClick={() => modalVisible()}
            >
              Ok
            </Button>
          </div>
        </div>
      )}
    </Spin>
  );
};

export default DetailsModal;

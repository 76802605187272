import {
  CheckOutlined,
  ClearOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Upload,
  Space,
  Spin,
  Switch,
  Modal,
} from "antd";
import ReactQuill from "react-quill";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import ImgCrop from "antd-img-crop";
import { useNavigate } from "react-router-dom";
import Male from "../../assets/images/male.jpg";
import Female from "../../assets/images/female.jpg";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const NewStaffForm = ({ iv, firsttime = false }) => {
  const [form] = Form.useForm();
  const { lg } = useBreakpoint();
  const [campuses, setCampuses] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [localCampuses, setLocalCampuses] = useState([]);
  const [positions, setPositions] = useState([]);
  const [schoolLevels, setSchoolLevels] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [teaching, setTeaching] = useState(true);
  const [days, setDays] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [firstModal, setFirstModal] = useState(false);
  const [quillValue, setQuillValue] = useState("");
  const router = useNavigate();
  const quillChange = (e) => {
    setQuillValue(e || "");
  };
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      console.log("iv:", iv);
      setTeaching(iv.isTeaching);
      quillChange(iv.info);

      setFirstModal(firsttime);

      AdminService.getFormData().then((data) => {
        setCampuses(data.data.campuses);
        setFaculties(data.data.faculties);
        setPositions(data.data.positions);
        setSchoolLevels(data.data.schoolLevels);
        setWorkingDays(data.data.workingDays);
        setLocalCampuses(data.data.localcampuses);
        console.log(data.data);
        setLoading(false);
        var yetti = [];
        iv &&
          iv.wDays &&
          iv.wDays.map((a) => {
            var tmp = {
              id: a,
              name: data.data.workingDays.filter((d) => d.id === a)[0].name,
            };
            yetti.push(tmp);
          });

        setDays(yetti);
      });
    }
  }, [initialRender]);
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      // ["link", "image", "video"],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const onFinish = (values) => {
    console.log(values);
    setIsLoading(true);
    values = {
      ...values,
      faculties: values.faculties === undefined ? [] : values.faculties,
      returnDate: values.returnDate ? moment(values.returnDate).format() : null,
      // photo: fileList[0]?.response.url || null,
      // schoolLevels: values.isTeaching ? values.schoolLevels : [],
      info: quillValue,
    };
    console.log("Gidiyor:", values);
    AdminService.newStaff(values)
      .then((data) => {
        notification.success({
          message: "Success!",
          description: "Staff member updated.",
        });
        localStorage.setItem("user", JSON.stringify(data.data));
        router("/list");
      })
      .finally((_) => {
        setIsLoading(false);
      });
  };
  const onReset = () => {
    form.resetFields();

    setFileList([]);
  };

  const teachingChanged = (v) => {
    console.log(v);
    setTeaching(v);
  };

  const workingDaysChanged = (v) => {
    console.log("WDDD:", v);
    let arr = workingDays.filter(function (item) {
      return v.indexOf(item.id) !== -1;
    });
    setDays(arr);
  };
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  return (
    iv && (
      <div className="max-w-5xl">
        <Modal
          visible={firstModal}
          title={`Dear ${
            JSON.parse(localStorage.getItem("user")).firstname
          } welcome. `}
          footer={false}
          onCancel={() => setFirstModal(false)}
        >
          <p>
            {" "}
            Welcome to <b>Amity College Directory Services</b>
          </p>{" "}
          <p className="mb-0">
            On this directory you will be able to see all Amity College staff
            details.
          </p>{" "}
          <p className="mb-0">We have also created your account.</p>{" "}
          <p className="mb-0">
            Please fill in the information you would like to share.
          </p>{" "}
          <p>You can always update these anytime needed.</p>{" "}
          <p className="mb-0">Thanks</p> <p>Amity ICT</p>
          <br />
          <br />
          <div className="justify-end flex">
            <Button
              icon={<CheckOutlined />}
              type="primary"
              onClick={() => setFirstModal(false)}
            >
              Ok
            </Button>
          </div>
        </Modal>
        {/* <Divider orientation="left" className="!text-sky-500">
          Please complete Member Form
        </Divider> */}
        <Spin spinning={loading}>
          <Form
            initialValues={iv}
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            layout={lg ? "horizontal" : "vertical"}
            {...GlobalVariables.formLayout}
          >
            <Form.Item>
              <Divider className="!text-sky-500" orientation="left">
                {iv.firstname} {iv.lastname} Personal Details
              </Divider>
            </Form.Item>
            {/* <Form.Item label="Staff Photo">
              <img
                src={
                  JSON.parse(localStorage.getItem("user")).photo
                    ? process.env.REACT_APP_API_BASEURL +
                      "photos/" +
                      JSON.parse(localStorage.getItem("user")).photo +
                      "/photo"
                    : JSON.parse(localStorage.getItem("user")).gender === "M"
                    ? Male
                    : Female
                }
                alt="staff_img"
                style={{
                  height: "240px",
                  width: "auto",
                  borderRight: "1px solid #ccc",
                }}
              />
              {/* <ImgCrop aspect={3 / 4} quality={0.6}>
                <Upload
                  action={process.env.REACT_APP_API_BASEURL + "upload"}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  }}
                  listType="picture-card"
                  accept=".jpg, .jpeg, .png"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={handlePreview}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </ImgCrop>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
            <Form.Item
              label="First name"
              name="firstname"
              rules={[
                { required: true, message: "This field is required!" },
                { type: "string", max: 25, min: 2 },
              ]}
            >
              <Input disabled placeholder="Type firstname" />
            </Form.Item>
        
            <Form.Item
              label="Last name"
              name="lastname"
              rules={[
                { required: true, message: "This field is required!" },
                { type: "string", max: 25, min: 2 },
              ]}
            >
              <Input disabled placeholder="Type lastname" />
            </Form.Item> 
            {/* <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select placeholder="Please select">
                {["Male", "Female"].map((g, index) => (
                  <Select.Option key={index} value={g}>
                    {g}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>  
            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                { required: true, message: "This field is required!" },
                { type: "email", message: "Wrong email format" },
              ]}
            >
              <Input disabled placeholder="Type email address" />
             </Form.Item>*/}
            <Form.Item
              label="Office Room Number"
              name="officeRoomNumber"
              rules={[
                {
                  type: "integer",
                  max: 9999,
                  min: 1000,
                  message: "Should be 4 digits",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Staff Room Number"
              name="staffRoomNumber"
              rules={[
                {
                  type: "integer",
                  max: 9999,
                  min: 1000,
                  message: "Should be 4 digits",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Office Phone Number"
              name="officePhone"
              rules={[
                {
                  type: "integer",
                  max: 9999,
                  min: 100,
                  message: "Should be 3 or 4 digits",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            {/* <Form.Item
              label="Personal Zoom Link"
              name="zoomLink"
              rules={[
                {
                  type: "url",
                  message: "Invalid url",
                },
              ]}
            >
              <Input placeholder="Type zoom link" />
            </Form.Item> */}
            <Form.Item label="Return Date  (If on leave)" name="returnDate">
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
            {/* <Form.Item label="Car 1 Number Plate" name="carplate1">
              <Input placeholder="Type plate" />
            </Form.Item>
            <Form.Item label="Car 2 Number Plate" name="carplate2">
              <Input placeholder="Type plate" />
            </Form.Item> */}
            {/* <Form.Item label="Allergies / Diet Preference" name="allergies">
              <Input placeholder="Type Allergies / Diet Preference" />
            </Form.Item> */}
            {
              <Form.Item label="Info (optional)">
                <ReactQuill
                  value={quillValue}
                  modules={modules}
                  theme="snow"
                  onChange={(e) => quillChange(e)}
                  placeholder="Please type your personal info (optional)"
                />
              </Form.Item>
            }
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
            >
              <Divider className="!text-sky-500" orientation="center">
                School Details
              </Divider>
              {/* <p>
                If the admin staff, please select the main building where the
                office is located.
              </p> */}
            </Form.Item>
            {/* <Form.Item
              label="Teaching Staff?"
              name="isTeaching"
              valuePropName="checked"
            >
              <Switch
                onChange={teachingChanged}
                checkedChildren="Yes"
                unCheckedChildren="No"

                // defaultChecked={true}
              />
            </Form.Item> */}
            {/* {teaching && (
              <Form.Item
                label="School Levels"
                name="schoolLevels"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Please select (You can choose multiple)"
                >
                  {schoolLevels.map((g, index) => (
                    <Select.Option key={index} value={g.id}>
                      {g.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )} */}
            {/* <Form.Item
              label="Main Campus"
              name="mainCampusId"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select placeholder="Please select">
                {campuses.map((g, index) => (
                  <Select.Option key={index} value={g.id}>
                    {g.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            {/* <Form.Item
              label="Positions"
              name="positions"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Please select (You can choose multiple)"
              >
                {positions.map((g, index) => (
                  <Select.Option key={index} value={g.id}>
                    {g.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> */}
            <Form.Item
              label="Faculties"
              name="faculties"
              //   rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Please select (You can choose multiple)"
              >
                {faculties.map((g, index) => (
                  <Select.Option key={index} value={g.id}>
                    {g.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
            >
              <Divider className="!text-sky-500" orientation="center">
                Work and campus days
              </Divider>
            </Form.Item>
            <Form.Item label="Working Days" name="wDays">
              <Select
                placeholder="Please select working days (You can choose multiple)"
                allowClear
                mode="multiple"
                onChange={workingDaysChanged}
              >
                {workingDays.map((g, index) => (
                  <Select.Option key={index} value={g.id}>
                    {g.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {days.map((wd, index) => (
              <Form.Item
                key={index}
                label={wd.name}
                name={wd.name}
                rules={[{ required: true }]}
              >
                <Select placeholder="Please select campus">
                  {localCampuses.map((g, index) => (
                    <Select.Option key={index} value={g.id}>
                      {g.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ))}
            <Form.Item
              className="mt-6"
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
            >
              <Space>
                <Button
                  type="default"
                  htmlType="reset"
                  icon={<ClearOutlined />}
                >
                  Clear
                </Button>

                <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    )
  );
};

export default NewStaffForm;

import { Col, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

const TimeTable = ({ db }) => {
  const [timetable, setTimetable] = useState(null);
  useEffect(() => {
    //   console.log("tt:", data);
    //  const db = data[0];

    var tekrar = db.gunler[0].timetable.length;
    var gunSayisi =
      db.gunler.length > 5 ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] : [0, 1, 2, 3, 4];
    var dataArray = [];
    for (let index = 0; index < tekrar; index++) {
      var satir = {};

      gunSayisi.forEach((day) => {
        if (day === 0) {
          satir = {
            ...satir,
            period: (
              <>
                <span>{db.gunler[day].timetable[index].description}</span>{" "}
                <br />
                <span className="text-sky-500">
                  {" "}
                  {moment(db.gunler[day].timetable[index].timeFrom).format(
                    "HH:mm"
                  )}
                  -
                  {moment(db.gunler[day].timetable[index].timeTo).format(
                    "HH:mm"
                  )}
                </span>
              </>
            ),
          };
        }
        satir = {
          ...satir,
          [`day${day}`]: getVeri(db.gunler[day].timetable[index].veri),
        };
      });
      dataArray.push(satir);
    }
    console.log("dataArray:", dataArray);
    var idAdded = dataArray.map((d, index) => {
      return { ...d, id: index };
    });
    console.log("dataArray:", idAdded);

    setTimetable(idAdded);
  }, []);

  const getVeri = (veri) => {
    if (veri === null || veri.length === 0) return "-";
    if (veri.length === 1) return veri[0];
    return `${veri[0]} and ${veri[1]}`;
  };
  const timecolumns =
    db.gunler.length > 5
      ? [
          {
            title: "Period",
            dataIndex: "period",
          },
          {
            title: "Monday",
            dataIndex: "day0",
            className: "text-center",
          },
          {
            title: "Tuesday",
            dataIndex: "day1",
            className: "text-center",
          },
          {
            title: "Wednesday",
            dataIndex: "day2",
            className: "text-center",
          },
          {
            title: "Thursday",
            dataIndex: "day3",
            className: "text-center",
          },
          {
            title: "Friday",
            dataIndex: "day4",
            className: "text-center ",
          },
          {
            title: "Monday",
            dataIndex: "day5",
            className: "text-center bg-yellow-50 border-l  border-gray-300",
          },
          {
            title: "Tuesday",
            dataIndex: "day6",
            className: "text-center bg-yellow-50",
          },
          {
            title: "Wednesday",
            dataIndex: "day7",
            className: "text-center bg-yellow-50",
          },
          {
            title: "Thursday",
            dataIndex: "day8",
            className: "text-center bg-yellow-50",
          },
          {
            title: "Friday",
            dataIndex: "day9",
            className: "text-center bg-yellow-50",
          },
        ]
      : [
          {
            title: "Period",
            dataIndex: "period",
          },
          {
            title: "Monday",
            dataIndex: "day0",
            className: "text-center",
          },
          {
            title: "Tuesday",
            dataIndex: "day1",
            className: "text-center",
          },
          {
            title: "Wednesday",
            dataIndex: "day2",
            className: "text-center",
          },
          {
            title: "Thursday",
            dataIndex: "day3",
            className: "text-center",
          },
          {
            title: "Friday",
            dataIndex: "day4",
            className: "text-center",
          },
        ];
  return (
    <div>
      <div>
        {timetable && (
          <div className="max-w-7xl">
            {db.gunler.length > 5 && (
              <Row className="py-2">
                <Col className="text-center font-bold " span={12}>
                  Week A Program
                </Col>
                <Col className="text-center font-bold" span={12}>
                  Week B Program
                </Col>
              </Row>
            )}
            <Table
              rowKey="id"
              className="border border-gray-300"
              dataSource={timetable}
              columns={timecolumns}
              size="small"
              bordered
              pagination={false}
              scroll={{ x: 1000 }}
            />
          </div>
        )}
        <br />
      </div>
    </div>
  );
};

export default TimeTable;

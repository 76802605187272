import { Row, Col, Card, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const AdminPage = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [reports, setReports] = useState(null);
  const router = useNavigate();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getReports().then((data) => {
        setReports(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div>
      {reports && (
        <div
          style={{
            backgroundColor: "#fafafa",
            padding: "15px",
            border: "1px solid #ccc",
          }}
        >
          <p className="text-sari text-3xl font-light text-sky-500">Stats</p>
          <Row gutter={[6, 6]} wrap>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router("/admin/staff")}
              >
                <Statistic
                  title="Staff Count"
                  value={reports.staff}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router("/admin/campuses")}
              >
                <Statistic
                  title="Campuses Count"
                  value={reports.campuses}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router("/admin/positions")}
              >
                <Statistic
                  title="Positions Count"
                  value={reports.positions}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router("/admin/faculties")}
              >
                <Statistic
                  title="Faculties Count"
                  value={reports.faculties}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AdminPage;

import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import DetailsModal from "../list/DetailsModal";
import NewStaffForm from "./NewStaffForm";

const Staff = () => {
  const [staff, setStaff] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [aramaText, setAramaText] = useState();
  const [searchResult, setSearchResult] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const { Search } = Input;

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStaffs().then((data) => {
        setStaff(data.data);
        setSearchResult(data.data);
        console.log("admin staff:", data.data);
      });
    }
  }, [initialRender]);

  const onSearch = (text) => {
    console.log(text.target.value);
    if (text.target.value !== undefined) {
      text = text.target.value;
      setAramaText(text);
      setSearchResult(
        staff.filter((s) => {
          return (
            s.firstname.toLowerCase().includes(text.toLowerCase()) ||
            s.lastname.toLowerCase().includes(text.toLowerCase())
          );
        })
      );
    } else {
      setSearchResult(staff);
    }
    if (text === "") {
      setSearchResult(staff);
    }
  };
  useEffect(() => {
    selectedStaff && setDetailsModalVisible(true);
  }, [selectedStaff]);
  const goDetails = (id) => {
    console.log(id);
    setSelectedStaff(id);
  };
  const deleteStaff = (id) => {
    console.log(id);
  };

  const columns = [
    {
      title: "First name",
      dataIndex: "firstname",
    },
    {
      title: "Last name",
      dataIndex: "lastname",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Main Campus",
      dataIndex: "campus",
    },
    {
      title: "Positions",
      dataIndex: "positions",
      render: (positions) =>
        positions.map((p) => <Tag key={p.id}>{p.name}</Tag>),
    },
    {
      title: "Actions",
      render: (item) => (
        <Space>
          <Tooltip title="Staff member details" placement="left" key={item.id}>
            <Button
              type="primary"
              onClick={() => goDetails(item.id)}
              icon={<SearchOutlined />}
            />
          </Tooltip>

          <Tooltip title="Delete staff member" placement="left" key={item.id}>
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => deleteStaff(item.id)}
              okText="Yes! Delete."
              cancelText="Cancel"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const closeModal = () => {
    console.log("set staff null");
    setSelectedStaff(null);

    setDetailsModalVisible(false);
  };
  return (
    <div>
      <Modal
        width="100%"
        style={{
          top: "10px",
          maxWidth: "700px",
        }}
        visible={detailsModalVisible}
        onCancel={() => closeModal()}
        onOk={() => closeModal()}
        footer={false}
      >
        <DetailsModal id={selectedStaff} modalVisible={closeModal} />
      </Modal>
      <Search
        value={aramaText}
        onChange={onSearch}
        className="max-w-md mb-4 "
        placeholder="Input search text"
        allowClear
        enterButton
        size="large"
        //  onSearch={onSearch}
      />
      <Table
        loading={!searchResult}
        columns={columns}
        dataSource={searchResult}
        size="small"
        rowKey="id"
      />
    </div>
  );
};

export default Staff;

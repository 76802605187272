import {
  ArrowRightOutlined,
  CloseOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Layout, Row, Col, Button, Drawer, Menu, Space, Affix } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useContext, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "src/context/GlobalContext.js";
import FooterBar from "../components/home/FooterBar.js";
import Logo from "../assets/images/logo.png";

const HomeLayout = () => {
  const { Header, Content } = Layout;
  const location = useLocation();
  const { lg } = useBreakpoint();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [actives, setActives] = useState([location.pathname.split("/").pop()]);
  const router = useNavigate();
  const { isAffix, setIsAffix } = useContext(GlobalContext);
  const menuClick = (e) => {
    router("/home/" + e.key);
    setActives([e.key]);
    setIsCollapsed(false);
  };
  const MainMenu = () => (
    <Affix offsetTop={0} onChange={(a) => setIsAffix(a ? true : false)}>
      <div>
        <Menu
          className="main-menum"
          mode={lg ? "horizontal" : "inline"}
          defaultSelectedKeys={actives}
          onClick={menuClick}
          style={{
            backgroundColor: isAffix ? "white" : "transparent",
            boxShadow: isAffix ? "0 0 10px #999" : "none",
            borderBottom: isAffix ? "1px solid #bbb" : "unset",
          }}
        >
          {isAffix && lg && (
            <Menu.Item key="/">
              <img src={Logo} className="h-10 inline my-2" alt="logo" />
            </Menu.Item>
          )}
          {(!isAffix || !lg) && <Menu.Item key="homepage">Home</Menu.Item>}
          <Menu.Item key="myprofile">My Profile</Menu.Item>
          <Menu.Item key="contact">Contact</Menu.Item>
        </Menu>
      </div>
    </Affix>
  );
  return (
    <Layout className="layout overflow-hidden !bg-[#f5f5f5]">
      <Header
      // style={{
      //   position: lg ? "fixed" : "unset",
      //   zIndex: 111,
      //   width: "100%",
      // }}
      >
        <div className="bg-gradient-to-b from-white to-f5f5f5 -mx-4 border-b border-gray-300">
          <div className="max-w-3xl mx-auto">
            <Row justify="space-between" align="middle" className="mx-4">
              <Col span={12}>
                <img
                  className="my-2"
                  src={Logo}
                  width={lg ? "110" : "100"}
                  alt="logo"
                />
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Space>
                  {JSON.parse(localStorage.getItem("user")).isRegistered && (
                    <Button
                      type="primary"
                      danger
                      icon={<CloseOutlined />}
                      onClick={() => router("/list")}
                    >
                      Close
                    </Button>
                  )}
                </Space>

                {/* {!lg && (
                  <Button
                    style={{ marginLeft: "5px" }}
                    icon={<MenuOutlined />}
                    size="large"
                    onClick={() => setIsCollapsed(true)}
                  />
                )} */}
              </Col>
            </Row>
          </div>
          {/* {lg && (
            <Row>
              <Col span={24}>
                <MainMenu />
              </Col>
            </Row>
          )} */}
        </div>
      </Header>
      <Content
        style={{
          padding: "0",
          //     marginTop: lg ? "125px" : "unset",
        }}
      >
        {/* <Drawer
          title={<img src={Logo} width="120" alt="logo" />}
          visible={isCollapsed}
          placement="left"
          closable={true}
          onClose={() => setIsCollapsed(false)}
        >
          <MainMenu />
        </Drawer> */}
        <div className="site-layout-content ">
          <Outlet />
        </div>
      </Content>
      <br />
      <br />
      <div className="bg-f5f5f5 border-t border-gray-300">
        <FooterBar />
      </div>
    </Layout>
  );
};

export default HomeLayout;

import { notification } from "antd";
import React from "react";
import { Route, Navigate } from "react-router-dom";

const AdminRoute = ({ children }) => {
  const myRole = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).role
    : "Guest";
  if (myRole !== "Admin") {
    notification.destroy();
    notification.warn({
      message: "Unauthorized transaction!!!",
      description: "Please login again!",
    });
    return <Navigate to="/list" replace />;
  } else {
    return children;
  }

  //     myRole === "Admin" ? (
  //   return children
  //     ) : (
  //       <Navigate replace to="login" state={{ from: location }} />
  //       //   <Redirect
  //       //     to={{
  //       //       pathname: "/login",
  //       //       state: { from: location },
  //       //     }}
  //       //   />
  //     )
};

export { AdminRoute };

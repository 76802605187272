import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Female from "../assets/images/female.jpg";
import Male from "../assets/images/male.jpg";
const MyCard = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [item, setItem] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      setItem(JSON.parse(localStorage.getItem("user")));
    }
  }, [initialRender]);
  return (
    item &&
    JSON.parse(localStorage.getItem("user")).isRegistered && (
      <div
        className="bg-white border border-gray-300 hover:shadow-lg hover:cursor-pointer"
        onClick={() => navigate("/home/myprofile")}
      >
        <Space>
          <img
            src={
              item.photo
                ? process.env.REACT_APP_API_BASEURL +
                  "photos/" +
                  item.photo +
                  "/photo"
                : item.gender === "M"
                ? Male
                : Female
            }
            alt="staff_img"
            style={{
              height: "40px",
              width: "30px",
              borderRight: "1px solid #ccc",
            }}
          />
          <div className="leading-4">
            <span className="  text-sky-500 mb-0">{item.fullname}</span>
            <br />
            <span style={{ fontSize: "11px" }}>Click to change profile </span>
          </div>
        </Space>
      </div>
    )
  );
};

export default MyCard;

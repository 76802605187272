import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const SchoolBoxTest = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState("loading...");
  AdminService.schoolBoxTest(
    searchParams.get("key"),
    searchParams.get("time"),
    searchParams.get("id"),
    searchParams.get("user")
  ).then((data) => {
    setData(data.data);
  });
  return (
    <div>
      <p>School Box Test Sayfasindan merhaba dunya!</p>
      <p>Key : {searchParams.get("key")}</p>
      <p>Time : {searchParams.get("time")}</p>
      <p>id : {searchParams.get("id")}</p>
      <p>user : {searchParams.get("user")}</p>
      <p>role : {searchParams.get("role")}</p>
      <p>Apiden donen data: {data}</p>
    </div>
  );
};

export default SchoolBoxTest;

import { Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Loader from "src/Loader";
import { AdminService } from "src/resources/AdminService";
import NewStaffForm from "../admin/NewStaffForm";

const MyProfile = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [initialValues, setInitialValues] = useState(null);
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      if (
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).isRegistered
      ) {
        AdminService.getMyFormData().then((data) => {
          console.log("gelen:", data.data);
          var values = {
            ...data.data,
            returnDate: data.data.returnDate
              ? moment(data.data.returnDate)
              : null,
            Monday: data.data.monday,
            Tuesday: data.data.tuesday,
            Wednesday: data.data.wednesday,
            Thursday: data.data.thursday,
            Friday: data.data.friday,
          };
          setInitialValues(values);
        });
      } else {
        var soyad = JSON.parse(localStorage.getItem("user")).lastname;
        var ad = JSON.parse(localStorage.getItem("user")).firstname;
        //   var soyad = JSON.parse(localStorage.getItem("user"))
        //   .fullname.split(" ")
        //   .pop();
        // var ad = JSON.parse(localStorage.getItem("user")).fullname.replace(
        //   " " + soyad,
        //   ""
        // );
        setInitialValues({
          email: JSON.parse(localStorage.getItem("user")).email,
          firstname: ad,
          lastname: soyad,
        });
      }
    }
  }, [initialRender]);
  return (
    <Spin spinning={!initialValues}>
      <div className="mx-auto max-w-3xl border-l border-r border-b border-gray-300 p-2 lg:p-6 bg-white shadow-lg">
        {/* // {!initialValues && <Loader />} */}
        {initialValues && (
          <NewStaffForm
            iv={initialValues}
            firsttime={Boolean(searchParams.get("firsttime"))}
          />
        )}
      </div>
    </Spin>
  );
};

export default MyProfile;
